<template>
    <div class="addFeedback">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; padding: 20px">
            <el-form enctype="multipart/form-data" ref="form" :model="form" label-width="100px" size="small">
                <el-form-item label="反馈机构">
                    <el-select v-model="form.deptCode" disabled>
                        <el-option value="" label="请选择" />
                        <el-option
                            v-for="dept in meta.deptCodes"
                            :label="dept.name"
                            :key="dept.code"
                            :value="dept.code"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="需求分类">
                    <el-select
                        disabled
                        filterable
                        v-model="form.menuCode"
                        @change="handleChangeGroup"
                        :loading="loadingDeptGroupFlag"
                    >
                        <el-option value="" label="请选择" />
                        <el-option
                            v-for="group in meta.groups"
                            :value="group.code"
                            :key="group.code"
                            :label="group.name"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="需求名称" prop="name">
                    <el-input
                        disabled
                        style="width: 30%"
                        type="text"
                        placeholder="需求名称"
                        v-model="form.name"
                        show-word-limit
                        maxlength="30"
                    />
                </el-form-item>
                <el-form-item label="需求场景" prop="scene">
                    <el-input
                        disabled
                        type="textarea"
                        placeholder="需求场景"
                        v-model="form.scene"
                        show-word-limit
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        maxlength="500"
                    />
                </el-form-item>
                <el-form-item label="需求描述" prop="descInfo">
                    <el-input
                        disabled
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="需求场景"
                        v-model="form.descInfo"
                        show-word-limit
                        maxlength="500"
                    />
                </el-form-item>
                <el-form-item label="附件">
                    <el-upload
                        disabled
                        class="upload-demo"
                        action="#"
                        :http-request="importFile"
                        multiple
                        :limit="5"
                        :on-exceed="handleExceed"
                        :on-change="handleChange"
                        :on-preview="handlePreview"
                        :file-list="fileList"
                    >
                        <el-button size="small" type="primary" disabled slot="tip">点击上传</el-button>
                    </el-upload>
                    <el-input v-model="form.file" style="display: none" />
                    <div style="margin-top: 10px; color: #bcbdc4">
                        <div>1.最多上传5个文件</div>
                        <div>2.文件上传限制大小为20M</div>
                        <div>3.支持扩展名：.rar .zip .doc .docx .pdf .jpg .jpeg .png .gif .bmp .xls .xlsx</div>
                    </div>
                </el-form-item>
                <el-form-item label="关闭原因" v-if="form.closeReason">
                    <el-input
                        disabled
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="需求场景"
                        v-model="form.closeReason"
                        show-word-limit
                        maxlength="500"
                    />
                </el-form-item>
                <el-form-item label="需求负责人" v-if="form.managerName">
                    <el-input disabled type="text" placeholder="需求场景" v-model="form.managerName" />
                </el-form-item>
                <el-form-item label="上线时间" v-if="form.finishTime">
                    <el-date-picker
                        placeholder="选择日期"
                        v-model="form.finishTime"
                        type="datetime"
                        :editable="false"
                        disabled
                    />
                </el-form-item>
                <el-form-item label="备注" v-if="form.remark">
                    <el-input
                        disabled
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="备注"
                        v-model="form.remark"
                        show-word-limit
                        maxlength="500"
                    />
                </el-form-item>
                <el-form-item label="反馈日志" v-if="form.logs" />
            </el-form>
            <div class="viewFeedbackLog" v-if="form.logs">
                <div class="logLine" v-for="(item, idx) in form.logs" :key="idx">{{ item }}</div>
            </div>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'AddFeedback',
    data() {
        return {
            fileList: [],
            isContinue: false,
            deptGroupCode: '',
            form: {
                deptCode: '',
                menuCode: '',
                uploadFiles: [],
                file: [],
            },
            meta: {
                groups: [],
                deptCodes: [],
                cards: [],
            },
            loadingDeptGroupFlag: true,
            url: {
                queryDeptByGroup: '/system/dept/deptTree',
                save: '/userFeedBack/createExtend',
            },
            viewAll: {},
        };
    },
    created() {
        this.handleChangeGroup();
        UrlUtils.QueryRemote(this, '/authedMenus', (res) => {
            this.meta.groups = res;
            this.loadingDeptGroupFlag = false;
        });
        this.form = this.$route.params.item;
        if (this.$route.params.item.nameAndUrls) {
            this.$route.params.item.nameAndUrls.forEach((item, index) => {
                item.uid = index;
            });
        }

        this.fileList = this.$route.params.item.nameAndUrls;
    },
    methods: {
        handleChange(file, fileList) {
            this.form.file = file;
        },

        importFile(file) {
            const formDatas = new FormData();
            formDatas.append('file', file.file.file);
            formDatas.append('name', file.file.name); //这"file" 代表参数名
        },
        handlePreview(file) {
            if (file.url == '') {
                this.$message.error('文件已过期');
                return;
            }
            if (
                file.name.includes('.jpg') ||
                file.name.includes('.png') ||
                file.name.includes('.bmp') ||
                file.name.includes('.gif') ||
                file.name.includes('.jpeg')
            ) {
                const imgWindow = window.open('');
                imgWindow &&
                    imgWindow.document.write(`<image src='${file.url}' style='display: flex; margin: 0 auto'/>`);
            } else {
                var a = document.createElement('a');

                var event = new MouseEvent('click');
                a.download = file.name;
                a.href = file.url;
                a.dispatchEvent(event);
            }
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择5个文件，本次选择了 ${files.length} 个文件，共选择了 ${
                    files.length + fileList.length
                } 个文件`
            );
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
        },
        handleChangeGroup() {
            const _this = this;
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + '', (rst) => {
                _this.meta.deptCodes = rst;
            });
        },
    },
};
</script>

<style>
.viewFeedbackLog {
    padding: 0 40px;
}
.logLine {
    line-height: 30px;
    color: #19c989;
}
</style>
